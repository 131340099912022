import {
    TableBody,
    TableCell,
    Label,
    TableRow,
    Table,
    Input,
    TableHeader,
    TableHeaderCell,
} from "@fluentui/react-components";
import { FormItem } from "../Models/FormModels";

export const GridInput = (props: {item: FormItem}) => {
    if (!props.item.gridLayout) {
        return (<div>Invalid gidlayout</div>)
    }
    
    const columns = props.item.gridLayout.columns;
    const rows = props.item.gridLayout.rows;
    const total = columns.length * rows.length;
    const defaultValue = props.item.gridLayout.defaultValue;
    if (!props.item.values) {
        props.item.values = Array(total).fill(defaultValue);
    }

    const updateValue = (index: number, newValue: string) => {
        try {
            if (props.item.values) {
                props.item.values[index] = newValue;
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    type rowProps = {
        rowIndex: number,
        rowTitle: string
    }

    const CreateRowElement = (rowProps: rowProps) => {
        return (
            <TableRow>
                <TableCell className="gridCellInput">{rowProps.rowTitle}</TableCell>
                {
                    columns.map((column, index) => {
                        const pos = (rowProps.rowIndex * columns.length) + index;
                        var value = defaultValue;
                        if (props.item.values && props.item.values.length > pos) {
                            value = props.item.values[pos];
                        }
                        return (
                            <TableCell key={index.toString()}>
                                <Input defaultValue={value ?? ""} size="large" className="gridCellInput"
                                    onChange={(e, d) => updateValue(pos, d.value)} />
                            </TableCell>
                        );
                    })
                }
            </TableRow>);
    }

    return (
        <div className="gridInputContainer">
            <Table arial-label="Default table">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell className="gridCellInput">
                            <Label title="" size="large" />
                        </TableHeaderCell>
                        {
                            columns.map((column, index) => {
                                return (<TableHeaderCell key={`HeaderRow${index.toString()}`} className="gridCellInput">
                                    {column}
                                </TableHeaderCell>)
                            })
                        }
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        rows.map((row, index) => {
                            return (<CreateRowElement key={index.toString()} rowIndex={index} rowTitle={row} />)
                        })
                    }
                </TableBody>
            </Table>
        </div >
    );
};