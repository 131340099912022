import * as React from "react";
import {
    Field, Input, Option, Textarea, Dropdown, Radio, RadioGroup,
    InputOnChangeData, RadioOnChangeData, OptionOnSelectData, Button
} from "@fluentui/react-components";
import { SearchBox } from "@fluentui/react-search-preview";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { TimePicker, } from "@fluentui/react-timepicker-compat";
import { MappingApi } from "../Services/MappingApi";
import { useMsal } from "@azure/msal-react";
import { AddressResult } from "../Models/MappingModels";
import { FormItem, GeoLocation } from "../Models/FormModels";
import moment from "moment";
import { useState } from "react";
import { NoteRegular, TasksAppRegular } from "@fluentui/react-icons";
import { NoteDialogView } from "./NoteDialog";

const dateFormatString = 'DD/MM/YYYY';
const dateTimeFormatstring = 'DD/MM/YYYY HH:mm';
const timeFormatstring = 'HH:mm'

const formatDateFromString = (dateString: string) => {
    console.log(`formatDateFromString called ${dateString}`);
    if (dateString.length > 0) {
        return moment(dateString, dateFormatString).toDate();
    }
    return null;
}

const formatTimeString = (date: Date) => {
    if (date) {
        return moment(date, dateTimeFormatstring).format(timeFormatstring);
    }
    return "";
}

const formatDateTimeString = (date: Date) => {
    if (date) {
        return moment(date, dateTimeFormatstring).format(dateTimeFormatstring);
    }
    return "";
}

const formatDateString = (date?: Date) => {
    if (date) {
        return moment(date, dateTimeFormatstring).format(dateFormatString);
    }
    return "";
}

export const ItemFooterButtonSet = (props: { item: FormItem }) => {
    const[showNote, setShowNote] = useState(false);
    if (!props.item.hasNote && !props.item.hasTask) {
        return null;
    }

    const noteClick = () => {
        setShowNote(true);
    }

    const closeNoteDialog = () => {
        setShowNote(false);
    }
    const noteColor = (props.item.note && props.item.note.length > 0) ? {color: "#37eb34"} : {};
    return (
    <div style={{marginTop: "10px"}}>
        {props.item.hasNote && (<Button icon={<NoteRegular />} appearance="transparent" onClick={() => noteClick()} style={noteColor}>Note</Button>)}
        {props.item.hasTask && (<Button icon={<TasksAppRegular />}>Tasks</Button>)}
        {showNote && (<NoteDialogView item={props.item} closeDialog={() => closeNoteDialog()} />)}
    </div>)
}

export const AddressLocation = (props: { item: FormItem }) => {
    const [showResults, setShowResults] = React.useState(false);
    const [addressResults, setAddressResults] = React.useState<AddressResult[]>();
    const [searchText, setSearchText] = React.useState("");

    const formItem = props.item;
    const msal = useMsal();

    const handleSearchEvent = (data: InputOnChangeData) => {
        console.log(`Search value: ${data.value}`);
        setSearchText(data.value);
        if (data.value.trim() === "") {
            console.log("Clear search result");
            setShowResults(false);
        }
    }

    const handleKeyEvent = (event: React.KeyboardEvent<HTMLElement>) => {
        if (!event || event.key !== "Enter") {
            return;
        }
        console.log(`Start searching address ${searchText}`)
        var api = new MappingApi();
        api.searchAddress(msal, searchText).then((results) => {
            setAddressResults(results);
            setShowResults(true);
        })
    }

    const resultItems = () => {
        return (
            addressResults?.map((address, index) => {
                return (<Radio value={index.toString()} key={index.toString()}
                    label={address.address.freeformAddress}
                    onChange={(e, d) => handleAddressSelected(d)}></Radio>)
            })
        );
    }

    const handleAddressSelected = (data: RadioOnChangeData) => {
        try {
            if (addressResults) {
                console.log(`selected ${data.value}`);
                var index = parseInt(data.value);

                var selected = addressResults[index];

                const loc: GeoLocation = {
                    latitude: selected.position.lat,
                    longitude: selected.position.lon
                }

                formItem.value = selected.address.freeformAddress;
                formItem.geoLocation = loc;
                setSearchText(formItem.value ?? "");
                setShowResults(false);
            }
        }
        catch (e) {
            console.error(e);
        }
    }



    const searchResults = (show: boolean) => {
        if (show && addressResults) {
            return (<RadioGroup>{resultItems}</RadioGroup>);
        }
        return null;
    }

    return (
        <div className="inputContainer">
            <Field label={props.item.title} size="large" required={props.item.required ?? false}>
                <SearchBox id="searchBar" size="large" placeholder={props.item.placeholder} className="searchBar"
                    onChange={(event, data) => handleSearchEvent(data)} value={searchText}
                    onKeyDown={(e) => handleKeyEvent(e)} />
            </Field>
            {searchResults(showResults)}
        </div>
    );
}

export const TextInput = (props: { item: FormItem }) => {
    const handleTextChanged = (data: InputOnChangeData) => {
        props.item.value = data.value;
    }
    return (
        <div className="inputContainer">
            <Field label={props.item.title} size="large" required={props.item.required ?? false}>
                <Input defaultValue={props.item.value ?? ""} size="large"
                    placeholder={props.item.placeholder} onChange={(e, d) => handleTextChanged(d)} />
            </Field>
        </div>
    );
};

export const TextMultiline = (props: { item: FormItem }) => {
    const [textValue, setTextValue] = useState(props.item.value);

    const updateTextValue = (value: string) => {
        setTextValue(value);
        props.item.value = value;
    }

    return (
        <div className="inputContainer">
            <Field label={props.item.title} size="large" required={props.item.required ?? false}>
                <Textarea value={textValue ?? ''} size="large" resize="vertical" onChange={(e, d) => updateTextValue(d.value)}
                    placeholder={props.item.placeholder} rows={props.item.rows} />
            </Field>
        </div>
    );
};


export const DropdownInput = (props: { item: FormItem }) => {
    const options = props.item.options ? props.item.options : [];

    const selectedOptions = props.item.value ? [props.item.value] : [];
    const [selectedValue, setSelectedValue] = React.useState(props.item.value ?? "");

    function handleSelection(data: OptionOnSelectData): void {
        //console.log(`${props.item.itemId}: value set to ${data.optionValue}`);
        props.item.value = data.optionValue;
        setSelectedValue(data.optionValue ?? "")
        if (props.item.options) {
            const option = props.item.options.find(q => q.optionText === data.optionValue);
            props.item.itemScore = option?.score ?? 0;
            //console.log(`setting up score for ${props.item.itemId}: ${props.item.itemScore}`);
        }
    }

    // /console.log(`${props.item.itemId} value is ${props.item.value}, required ${props.item.required}`)

    return (
        <div className="inputContainer">
            <Field label={props.item.title} size="large" required={props.item.required ?? false}>
                <Dropdown size="large" onOptionSelect={(e, d) => handleSelection(d)}
                    placeholder={props.item.placeholder} defaultSelectedOptions={selectedOptions} defaultValue={selectedValue}>
                    {options?.map((option, index) => (
                        <Option key={`${props.item.itemId}-${index.toString()}`}>
                            {option.optionText ?? ''}
                        </Option>
                    ))}
                </Dropdown>
            </Field>
        </div>
    );
};

export const DateTimeInput = (props: {item: FormItem, useDefaultValue: boolean}) => {
    React.useEffect(() => {
        var defaultDate: Date | undefined;
        if (props.item.value) {
            defaultDate = moment(props.item.value, dateTimeFormatstring).toDate();
        } else {
            if (props.useDefaultValue === true) {
                defaultDate = new Date();
            }
        }
        setSelectedDateTime(defaultDate);
        const timeString = moment(defaultDate).format(timeFormatstring);
        setSelectedTimeString(timeString);
    }, [props.item.value, props.useDefaultValue]);

    const handleDateChanged = (date: Date | null | undefined) => {
        if (date) {
            const dateString = formatDateString(date);
            if (selectedTimeString && selectedTimeString.length > 0) {
                const dateTimeString = `${dateString} ${selectedTimeString}`;
                date = moment(dateTimeString, dateTimeFormatstring).toDate();
            }
            props.item.value = formatDateTimeString(date);
            //console.log(`handleDateChanged called for ${props.item.value}: ${date}`);
            setSelectedDateTime(date);

        }
    }

    const handleTimeChanged = (date: Date | null | undefined) => {
        console.log(`handleTimeChanged called for ${date}`);
        if (date) {
            const timeString = moment(date).format(timeFormatstring);
            props.item.value = formatDateTimeString(date);
            //console.log(`handleTimeChanged called for ${timeString}: ${props.item.value}`);
            setSelectedTimeString(timeString);
        }
    }

    //const defaultTimeString  = moment(defaultDate, dateTimeFormtstring).format("HH:mm");

    const [selectedDateTime, setSelectedDateTime] = React.useState<Date | undefined>(undefined);

    const [selectedTimeString, setSelectedTimeString] = React.useState<string>("");

    return (
        <div className="datetimeInput">
            <Field label={props.item.title} size="large" required={props.item.required ?? false}>
                <DatePicker placeholder={props.item.placeholder} value={selectedDateTime ?? null} size="large" formatDate={formatDateString}
                    disableAutoFocus={true} allowTextInput={true} parseDateFromString={formatDateFromString}
                    onSelectDate={handleDateChanged} />
            </Field>
            <Field label="Time" size="large" required={props.item.required ?? false}>
                <TimePicker size="large" placeholder="Select time" freeform={true} hourCycle="h23"
                    defaultOpen={false} selectedTime={selectedDateTime ?? null} value={selectedTimeString}
                    onTimeChange={(e, d) => handleTimeChanged(d.selectedTime)} formatDateToTimeString={formatTimeString} />
            </Field>
        </div>
    );
};


export const DateInput = (props: {item: FormItem, useDefaultValue: boolean}) => {
    var defaultDate: Date | undefined;
    if (props.item.value) {
        defaultDate = moment(props.item.value, dateFormatString).toDate();
    } else {
        if (props.useDefaultValue === true) {
            defaultDate = new Date();
        }
    }
    const [defaultSelectedTime, setDefaultSelectedTime] = React.useState(defaultDate ?? undefined);

    const handleDateChanged = (date: Date | null | undefined) => {
        if (date) {
            props.item.value = formatDateString(date);
            console.log(`handleDateChanged called for ${props.item.value}`);
            setDefaultSelectedTime(date);
        }
    }

    return (
        <div className="inputContainer">
            <Field label={props.item.title} size="large" required={props.item.required ?? false}>
                <DatePicker id={props.item.itemId} placeholder={props.item.placeholder} size="large"
                    formatDate={formatDateString} allowTextInput={true} value={defaultSelectedTime ?? null} disableAutoFocus={true}
                    parseDateFromString={formatDateFromString}
                    onSelectDate={handleDateChanged} />
            </Field>
        </div>
    );
};

