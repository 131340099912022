

export type FormSummary = {
  id: string;
  title?: string;
  submittedBy: string;
  submittedOn?: Date;
  status: string;
}

export type FormOptions = {
  id: string;
  categories: FormCategory[];
}

export type FormCategory = {
  name: string;
  soccOnly: boolean;
  items: TemplateItem[];
}

export type TemplateItem = {
  reportId: string;
  displayName: string;
  description: string;
  viewPage: string;
}

export type Condition = {
  itemId: string;
  value: string;
}

export type FormTemplate = {
  id: string;
  title?: string;
  postProcessorName?: string;
  showScore: boolean;
  lastPage: number;
  pages: FormPage[];
  resultSets?: ResutSet[];
};


export type FormPage = {
  pageTitle?: string;
  fromPageIndex: number;
  andConditions?: Condition[];
  orConditions?: Condition[];
  isGridLayout: boolean;
  gridWidth?: number[];
  items: FormItem[];

};

export type FormItem = {
  itemId: string;
  inputType: string;
  andConditions?: Condition[];
  orConditions?: Condition[];
  placeholder?: string;
  title?: string;
  description?: string;
  options?: OptionItem[];
  itemScore: number;
  value?: string;
  values?: string[];
  required: boolean;
  hasNote: boolean;
  hasTask: boolean;
  note?: string;
  rows: number;
  geoLocation?: GeoLocation;
  gridLayout?: GridLayout;
};

export type OptionItem = {
  optionGroup?: string,
  optionText?: string,
  score?: number
};

export type GeoLocation = {
  latitude: number;
  longitude: number;
}

export type GridLayout = {
  rows: string[],
  columns: string[],
  cellWidth: number,
  defaultValue: string
}

export type ResutSet = {
  resultText: string,
  from: number,
  to: number
}

export const findAndConditon = (template: FormTemplate, conditions: Condition[]) => {
  for (var i = 0; i < conditions.length; i++) {
    const condition = conditions[i];
    const findPage = template.pages.find((page) => page.items.find((item) => item.itemId === condition.itemId && item.value === condition.value));
    if (!findPage) return false;
  };
  return true;
}

export const findOrConditon = (template: FormTemplate, conditions: Condition[]) => {
  for (var i = 0; i < conditions.length; i++) {
      const condition = conditions[i];
      const findPage = template.pages.find((page) => page.items.find((item) => item.itemId === condition.itemId && item.value === condition.value));
      if (findPage) {
          return true;
      }
  }
  return false;
}

export const checkItemCondition = (template: FormTemplate, item: FormItem) => {
  if (item.andConditions) {
      return findAndConditon(template, item.andConditions);
  } else if (item.orConditions) {
      return findOrConditon(template, item.orConditions);
  }
  return true;
}
