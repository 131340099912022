import { IMsalContext } from "@azure/msal-react";
import { AddressResult } from "../Models/MappingModels";
import { loginRequest, apiConfig } from "../authConfig";

export class MappingApi {

    async searchAddress(msal: IMsalContext, searchText: string) {
        var result = await msal.instance.acquireTokenSilent(loginRequest);
        var search = encodeURI(searchText);
        var url = `${apiConfig.apiEndpoint}/api/Mapping/AddressAutoComplete?address=${search}`;
        console.log(`Getting template from ${url}: ${result.accessToken}`);
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${result.accessToken}`
            })
        });
        let data: AddressResult[] = await response.json();
        return data;
    }
}